import React from "react";

import { AnimLayerBankSelectionList } from "~animations/__layers__/mock_ui_components/AnimLayerBankSelectionList";
import { AnimLayerSearchBar } from "~animations/__layers__/mock_ui_components/AnimLayerSearchBar";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerBankSelectionListProps } from "~animations/__layers__/mock_ui_components/AnimLayerBankSelectionList";
import type { AnimLayerEcommerceItemsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceItems";
import type { AnimLayerEcommerceOrderDetailsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceOrderDetails";
import type { AnimLayerIconListProps } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import type { AnimLayerSearchBarProps } from "~animations/__layers__/mock_ui_components/AnimLayerSearchBar";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiBankSelectionProps extends StoryblokBlok, BoxProps {
  title?: string;
  animLayerEcommerceItems: Array<StoryblokBlok & AnimLayerEcommerceItemsProps>;
  animLayerSearchBar: Array<StoryblokBlok & AnimLayerSearchBarProps>;
  animLayerEcommerceOrderDetails: Array<
    StoryblokBlok & AnimLayerEcommerceOrderDetailsProps
  >;
  animLayerPaymentMethods: Array<StoryblokBlok & AnimLayerIconListProps>;
  animLayerBankSelection: Array<
    StoryblokBlok & AnimLayerBankSelectionListProps
  >;
}

export function AnimMockUiBankSelection({
  animLayerSearchBar: animLayerSearchBarBlokArray,
  animLayerBankSelection: animLayerBankSelectionBlokArray,

  title,
  ...rest
}: AnimMockUiBankSelectionProps) {
  const [animLayerSearchBar] = animLayerSearchBarBlokArray || [];
  const [animLayerBankSelection] = animLayerBankSelectionBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      {title && <AnimLayerUiTopNav title={title} iconLeft="faChevronLeft" />}

      {animLayerSearchBar && (
        <AnimLayerSearchBar marginX="spacing2" {...animLayerSearchBar} />
      )}
      {animLayerBankSelection && (
        <AnimLayerBankSelectionList
          marginX="spacing2"
          {...animLayerBankSelection}
        />
      )}
    </AnimatedMockUiWrapper>
  );
}
