import React from "react";

import { AnimLayerEcommerceOrderDetails } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceOrderDetails";
import { AnimLayerEcommerceRefundItems } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceRefundItems";
import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerEcommerceOrderDetailsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceOrderDetails";
import type { AnimLayerEcommerceRefundItemsProps } from "~animations/__layers__/mock_ui_components/AnimLayerEcommerceRefundItems";
import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiEcommerceRefund1Props
  extends StoryblokBlok,
    BoxProps {
  title?: string;
  animLayerEcommerceRefundItems: Array<
    StoryblokBlok & AnimLayerEcommerceRefundItemsProps
  >;
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  animLayerEcommerceOrderDetails: Array<
    StoryblokBlok & AnimLayerEcommerceOrderDetailsProps
  >;
}

export function AnimMockUiEcommerceRefund1({
  animLayerEcommerceRefundItems: animLayerEcommerceRefundItemsBlokArray,
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  animLayerEcommerceOrderDetails: animLayerEcommerceOrderDetailsBlokArray,
  title,
  ...rest
}: AnimMockUiEcommerceRefund1Props) {
  const [animLayerEcommerceRefundItems] =
    animLayerEcommerceRefundItemsBlokArray || [];
  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];
  const [animLayerEcommerceOrderDetails] =
    animLayerEcommerceOrderDetailsBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      {title && <AnimLayerUiTopNav title={title} iconLeft="faChevronLeft" />}

      {animLayerEcommerceRefundItems && (
        <AnimLayerEcommerceRefundItems
          marginX="spacing2"
          {...animLayerEcommerceRefundItems}
        />
      )}

      {animLayerEcommerceOrderDetails && (
        <AnimLayerEcommerceOrderDetails
          marginX="spacing2"
          {...animLayerEcommerceOrderDetails}
        />
      )}

      {animLayerFakeButton && (
        <AnimLayerFakeButton
          marginTop="auto"
          marginBottom="spacing4"
          size="md"
          marginX="spacing2"
          {...animLayerFakeButton}
        />
      )}
    </AnimatedMockUiWrapper>
  );
}
