import React from "react";

import { Box } from "~components/Box";

import { AnimLayerBalanceCardList } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardList";
import { AnimLayerBankTabs } from "~animations/__layers__/mock_ui_components/AnimLayerBankTabs";
import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerUiBottomNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiBottomNav";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerBalanceCardListProps } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardList";
import type { AnimLayerBankTabsProps } from "~animations/__layers__/mock_ui_components/AnimLayerBankTabs";
import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiAccountAggregationProps
  extends StoryblokBlok,
    BoxProps {
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  animLayerBalanceCards: Array<StoryblokBlok & AnimLayerBalanceCardListProps>;
  animLayerBankTabs: Array<StoryblokBlok & AnimLayerBankTabsProps>;
  title: string;
  bottomInfo: string;
}

export function AnimMockUiAccountAggregation({
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  animLayerBankTabs: animLayerBankTabsBlokArray,
  animLayerBalanceCards: animLayerBalanceCardsBlokArray,
  title,
  bottomInfo,
  ...rest
}: AnimMockUiAccountAggregationProps) {
  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];
  const [animLayerBankTabs] = animLayerBankTabsBlokArray || [];
  const [animLayerBalanceCards] = animLayerBalanceCardsBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      {title && <AnimLayerUiTopNav title={title} iconRight="faUserCircle" />}
      <Box
        display="flex"
        flexDirection="column"
        gap="spacing1"
        paddingX="spacing2"
        paddingBottom="spacing1"
        flexGrow="1"
      >
        {animLayerBankTabsBlokArray && (
          <AnimLayerBankTabs tabs={animLayerBankTabs?.tabs} />
        )}

        {animLayerBalanceCards && (
          <AnimLayerBalanceCardList {...animLayerBalanceCards} />
        )}

        {bottomInfo && (
          <Box textAppearance="body_sm" textAlign="center" marginTop="auto">
            {bottomInfo}
          </Box>
        )}
        {animLayerFakeButton && (
          <AnimLayerFakeButton size="md" {...animLayerFakeButton} />
        )}
      </Box>
      <AnimLayerUiBottomNav __position="relative" __marginTop="auto" />
    </AnimatedMockUiWrapper>
  );
}
