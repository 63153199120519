import React from "react";

import { Box } from "~components/Box";

import { SvgIconTickShield } from "~animations/__layers__/animated_icons/SvgIconTickShield";
import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiVerification1Props extends StoryblokBlok, BoxProps {
  title?: string;
  description?: string;
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
}

export function AnimMockUiVerification1({
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  description,
  title,
  ...rest
}: AnimMockUiVerification1Props) {
  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];

  return (
    <AnimatedMockUiWrapper
      justifyContent="center"
      // alignItems="center"
      {...rest}
    >
      {title && <AnimLayerUiTopNav title={title} iconLeft="faChevronLeft" />}
      <Box marginY="auto">
        <Box width="gridSpan1" marginBottom="spacing1" marginX="auto">
          <SvgIconTickShield />
        </Box>
        {description && (
          <Box
            marginX="spacing2"
            textAlign="center"
            textAppearance="body_sm"
            color="text_lowContrast"
          >
            {description}
          </Box>
        )}
      </Box>

      {animLayerFakeButton && (
        <AnimLayerFakeButton
          marginTop="auto"
          marginBottom="spacing4"
          size="md"
          marginX="spacing2"
          {...animLayerFakeButton}
        />
      )}
    </AnimatedMockUiWrapper>
  );
}
