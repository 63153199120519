import React from "react";

import { AnimLayerTransactionList } from "~animations/__layers__/mock_ui_components/AnimLayerTransactionList";
import { AnimLayerUiAccountInfoCard } from "~animations/__layers__/mock_ui_components/AnimLayerUiAccountInfoCard";
import { AnimLayerUiBottomNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiBottomNav";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerTransactionListProps } from "~animations/__layers__/mock_ui_components/AnimLayerTransactionList";
import type { AnimLayerUiAccountInfoCardProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiAccountInfoCard";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimMockUiAccountOverviewProps
  extends StoryblokBlok,
    Omit<BoxProps, "lang"> {
  textWithdrawalComplete?: string;
  textSuccess?: string;
  textComplete?: string;
  title?: string;
  animLayerTransactionList: Array<
    StoryblokBlok & AnimLayerTransactionListProps
  >;
  animLayerUiAccountInfoCard: Array<
    StoryblokBlok & AnimLayerUiAccountInfoCardProps
  >;
}

export function AnimMockUiAccountOverview({
  animLayerTransactionList: animLayerTransactionListBlokArray,
  animLayerUiAccountInfoCard: animLayerUiAccountInfoCardBlokArray,
  title,
  ...rest
}: AnimMockUiAccountOverviewProps) {
  const [animLayerTransactionList] = animLayerTransactionListBlokArray || [];
  const [animLayerUiAccountInfoCard] =
    animLayerUiAccountInfoCardBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      {title && <AnimLayerUiTopNav title={title} iconRight="faUserCircle" />}

      {animLayerUiAccountInfoCard && (
        <AnimLayerUiAccountInfoCard
          marginX="spacing2"
          {...animLayerUiAccountInfoCard}
        />
      )}

      {animLayerTransactionList && (
        <AnimLayerTransactionList {...animLayerTransactionList} />
      )}
      <AnimLayerUiBottomNav marginTop="auto" />
    </AnimatedMockUiWrapper>
  );
}
