import React from "react";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { SvgIconLoader } from "~animations/__layers__/animated_icons/SvgIconLoader";
import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerUiCenteredText } from "~animations/__layers__/mock_ui_components/AnimLayerUiCenteredText";
import { AnimatedMockUiWrapper } from "~animations/animation_primitives/AnimatedMockUiWrapper";

import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import type { AnimLayerUiCenteredTextProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiCenteredText";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface PaymentSuccessDetail extends StoryblokBlok, BoxProps {
  textLeft: string;
  textRight: string;
}
export interface AnimMockUiLoadingProps extends StoryblokBlok, BoxProps {
  animLayerFakeButton: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  animLayerUiCenteredText: Array<StoryblokBlok & AnimLayerUiCenteredTextProps>;
  details: Array<PaymentSuccessDetail>;
}

export function AnimMockUiLoading({
  animLayerFakeButton: animLayerFakeButtonBlokArray,
  animLayerUiCenteredText: animLayerUiCenteredTextBlokArray,
  details,
  ...rest
}: AnimMockUiLoadingProps) {
  const [animLayerFakeButton] = animLayerFakeButtonBlokArray || [];
  const [animLayerUiCenteredText] = animLayerUiCenteredTextBlokArray || [];

  return (
    <AnimatedMockUiWrapper {...rest}>
      <Box
        marginTop="spacing8"
        marginBottom="spacing5"
        width="gridSpan1"
        marginX="auto"
      >
        <SvgIconLoader />
      </Box>

      {animLayerUiCenteredText && (
        <AnimLayerUiCenteredText
          marginX="spacing2"
          {...animLayerUiCenteredText}
        />
      )}

      <Box marginX="spacing2">
        {details &&
          details.map((detail) => {
            return (
              <StoryblokEditable key={detail.textLeft} {...detail}>
                <Box
                  data-motion={ANIMATED.secondary}
                  display="flex"
                  alignItems="center"
                  textAppearance="body_md"
                  marginBottom="spacing1"
                  justifyContent="space-between"
                  key={detail.textLeft}
                >
                  <Box color="text_lowContrast">{detail.textLeft}</Box>
                  <Box fontWeight="semibold">{detail.textRight}</Box>
                </Box>
              </StoryblokEditable>
            );
          })}
      </Box>

      {animLayerFakeButton && (
        <AnimLayerFakeButton
          marginTop="auto"
          marginBottom="spacing4"
          size="md"
          marginX="spacing2"
          {...animLayerFakeButton}
        />
      )}
    </AnimatedMockUiWrapper>
  );
}
